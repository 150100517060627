import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-courses-page',
    templateUrl: './courses-page.component.html',
    styleUrls: ['./courses-page.component.scss']
})
export class CoursesPageComponent implements OnInit {

    constructor() { }

    ngOnInit(): void {
    }

    pageTitleArea: pageTitle[] = [
        {
            title: 'Courses'
        }
    ]
    singleCoursesBox: coursesContent[] = [

        {
            courseImg: 'assets/img/courses/img4.jpg',
            coursePrice: 'free',
            coursePriceClass: 'free',
            authorImg: 'assets/img/user1.jpg',
            authorName: 'Yalezo Ntsikelelos',
            title: 'The Data Science Course: Complete Data Science',
            link: 'courses-details',
            paragraphText: 'COMING SOON!',
            courseDuration: '8 Weeks Long',
            studentApply: 'Available Now'
        },
        {
            courseImg: 'assets/img/courses/img5.jpg',
            coursePrice: 'free',
            coursePriceClass: 'free',
            authorImg: 'assets/img/user2.jpg',
            authorName: 'Mkhululi Buzwa',
            title: 'Java Programming Masterclass for Developers',
            link: 'courses-details',
            paragraphText: 'COMING SOON!',
            courseDuration: '7 Weeks Long',
            studentApply: 'Available Now'
        },
        {
            courseImg: 'assets/img/courses/img6.jpg',
            coursePrice: 'free',
            coursePriceClass: 'free',
            authorImg: 'assets/img/user3.jpg',
            authorName: 'Mkhululi Buzwa',
            title: 'Machine Learning A-Z™: Hands-On Python',
            link: 'courses-details',
            paragraphText: 'COMING SOON!',
            courseDuration: '2 Weeks Long',
            studentApply: 'Not Available'
        }
    ]

}
class pageTitle {
    title : string;
}
class coursesContent {
    courseImg : string;
    coursePrice : string;
    coursePriceClass : string;
    authorImg : string;
    authorName : string;
    title : string;
    link : string;
    paragraphText : string;
    courseDuration : string;
    studentApply : string;
}
