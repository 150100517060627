<section class="page-title-area">
    <div class="container">
      
    </div>
  <div class="login-container">
    <h2>Login</h2>
    <form (ngSubmit)="OnSubmitLogin()">
        <div>
            <label for="email">Email</label>
            <input type="email" id="email" [(ngModel)]="email" name="email" required>
        </div>

        <div>
            <label for="password">Password</label>
            <input type="password" id="password" [(ngModel)]="password" name="password" required>
        </div>
        <button type="submit">Login</button>

    </form>

  </div>
</section>