<app-homeone-banner></app-homeone-banner>

<app-solutions></app-solutions>

<app-homeone-services></app-homeone-services>

<app-homeone-testimonials></app-homeone-testimonials>


<app-homeone-blog></app-homeone-blog>

<app-project-start></app-project-start>
