import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-homeone-services',
    templateUrl: './homeone-services.component.html',
    styleUrls: ['./homeone-services.component.scss']
})
export class HomeoneServicesComponent implements OnInit {

    constructor() { }

    ngOnInit(): void {
    }

    sectionTitle: sectionTitleContent[] = [
        {
            subTitle: 'Our Services',
            title: 'We Offer Professional Solutions For Business',
            paragraphText: 'we specialize in delivering top-tier professional solutions tailored to meet the unique needs of businesses across diverse industries.'
        }
    ]
    singleServicesBox: ServicesBoxContent[] = [
        {
            icon: 'assets/img/services/icon1.png',
            title: 'Data Analytics',
            paragraphText: 'Unlocking Insights, Driving Success: Your Data Analytics Partner.',
            link: 'services-details',
            linkText: 'Read More',
            linkIcon: 'flaticon-right'
        },
        {
            icon: 'assets/img/services/icon2.png',
            title: 'AI & ML Development',
            paragraphText: 'Innovate with Intelligence: AI & ML Solutions Tailored for Tomorrow.',
            link: 'services-details',
            linkText: 'Read More',
            linkIcon: 'flaticon-right'
        },
        {
            icon: 'assets/img/services/icon3.png',
            title: 'Mobile Development',
            paragraphText: 'Empowering Your Vision: Mobile Solutions, Unleashed',
            link: 'services-details',
            linkText: 'Read More',
            linkIcon: 'flaticon-right'
        },

        {
            icon: 'assets/img/services/icon5.png',
            title: 'Web Development',
            paragraphText: 'Elevate Your Online Presence: Crafting Web Solutions for Success.',
            link: 'services-details',
            linkText: 'Read More',
            linkIcon: 'flaticon-right'
        },
         {
                    icon: 'assets/img/services/icon4.png',
                    title: 'Predictive Analytics',
                    paragraphText: 'Charting Tomorrow: Harnessing Data for Predictive Precision.',
                    link: 'services-details',
                    linkText: 'Read More',
                    linkIcon: 'flaticon-right'
                },
    ]
    loadMoreBtn: loadMore[] = [
        {
            link: 'services-details',
            linkText: 'Load More',
            linkIcon: 'flaticon-refresh'
        }
    ]

}
class sectionTitleContent {
    subTitle : string;
    title : string;
    paragraphText : string;
}
class ServicesBoxContent {
    icon : string;
    title : string;
    paragraphText : string;
    link : string;
    linkText : string;
    linkIcon : string;
}
class loadMore {
    link : string;
    linkText : string;
    linkIcon : string;
}
