<section class="page-title-area">
    <div class="container">
        <div class="page-title-content" *ngFor="let pageTitle of pageTitleArea;">
            <h2>{{pageTitle.title}}</h2>
            <ul>
                <li><a routerLink="/">Home</a></li>
                <li>{{pageTitle.title}}</li>
            </ul>
        </div>
    </div>
    <h6>Coming  Soon</h6>
    <h6>Coming  Soon</h6>
    <h6>Coming  Soon</h6>
    <h6>Coming  Soon</h6>
    <h6>Coming  Soon</h6>
    <h6>Coming  Soon</h6>
    <h6>Coming  Soon</h6>
    <h6>Coming  Soon</h6>
    <h6>Coming  Soon</h6>
    <h6>Coming  Soon</h6>
    <h6>Coming  Soon</h6>
    <h6>Coming  Soon</h6>
    <h6>Coming  Soon</h6>
    <h6>Coming  Soon</h6>
    <h6>Coming  Soon</h6>
</section>
