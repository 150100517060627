import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/auth.service';
import { ErrorPageComponent } from '../../pages/error-page/error-page.component';

@Component({
  selector: 'app-zendataplatform',
  templateUrl: './zendataplatform.component.html',
  styleUrl: './zendataplatform.component.scss'
})
export class ZendataplatformComponent implements OnInit {
  email:string = '';
  password:string = '';
  errorMessage:string = '';


 constructor(private router:Router,private authservice:AuthService) { }

    ngOnInit(): void {
    }

    OnSubmitLogin(){
      console.log('email',this.email);
      console.log('password',this.password);
      this.authservice.login(this.email,this.password).subscribe(
        response =>{
          alert("sucesssfully  login");
        },
        error =>{
          this
        }
      )
    }



}
