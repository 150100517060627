import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-services-page',
    templateUrl: './services-page.component.html',
    styleUrls: ['./services-page.component.scss']
})
export class ServicesPageComponent implements OnInit {

    constructor() { }

    ngOnInit(): void {
    }

    pageTitleArea: pageTitle[] = [
        {
            title: 'Services'
        }
    ]
   singleServicesBox: ServicesBoxContent[] = [
           {
               icon: 'assets/img/services/icon1.png',
               title: 'Data Analytics',
               paragraphText: 'Unlocking Insights, Driving Success: Your Data Analytics Partner.',
               link: 'services-details',
               linkText: 'Read More',
               linkIcon: 'flaticon-right'
           },
           {
               icon: 'assets/img/services/icon2.png',
               title: 'AI & ML Development',
               paragraphText: 'Innovate with Intelligence: AI & ML Solutions Tailored for Tomorrow.',
               link: 'services-details',
               linkText: 'Read More',
               linkIcon: 'flaticon-right'
           },
           {
               icon: 'assets/img/services/icon3.png',
               title: 'Mobile Development',
               paragraphText: 'Empowering Your Vision: Mobile Solutions, Unleashed',
               link: 'services-details',
               linkText: 'Read More',
               linkIcon: 'flaticon-right'
           },

           {
               icon: 'assets/img/services/icon5.png',
               title: 'Web Development',
               paragraphText: 'Elevate Your Online Presence: Crafting Web Solutions for Success.',
               link: 'services-details',
               linkText: 'Read More',
               linkIcon: 'flaticon-right'
           },
            {
                       icon: 'assets/img/services/icon4.png',
                       title: 'Predictive Analytics',
                       paragraphText: 'Charting Tomorrow: Harnessing Data for Predictive Precision.',
                       link: 'services-details',
                       linkText: 'Read More',
                       linkIcon: 'flaticon-right'
                   },
       ]

}
class pageTitle {
    title : string;
}
class ServicesBoxContent {
    icon : string;
    title : string;
    paragraphText : string;
    link : string;
    linkText : string;
    linkIcon : string;
}
