import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private apiUrl = " ";
  private currentUserSubject:BehaviorSubject<any>;
  private currentUser:BehaviorSubject<any>;

  constructor(private http:HttpClient) {
    this.currentUserSubject = new BehaviorSubject<any>(JSON.parse(localStorage.getItem('currentUser')));
   // this.currentUser = this.currentUserSubject.asObservable();

   }

   public get currentUserValue():any{
    return this.currentUserSubject.value;
   }

  login(email:string,password:string):Observable<any>{

    return this.http.post<any>(this.apiUrl,{email,password}).pipe(
        map(response =>{

          if(response && response.token){
            localStorage.setItem('currentUser',JSON.stringify(response));
            this.currentUserSubject.next(response);
          }
          return response
        })

    );

  }
  logout(){
    localStorage.removeItem('currentUser');
    this.currentUserSubject.next(null);
  }
}
