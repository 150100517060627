import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-homeone-testimonials',
    templateUrl: './homeone-testimonials.component.html',
    styleUrls: ['./homeone-testimonials.component.scss']
})
export class HomeoneTestimonialsComponent implements OnInit {

    constructor() { }

    ngOnInit(): void {
    }

    sectionTitle: sectionTitleContent[] = [
        {
            subTitle: "Testimonials",
            title: 'What Our Clients Are Saying?',
            paragraphText: '.'
        }
    ]
    singleTestimonialsItem: TestimonialsItemContent[] = [
        {
            paragraphText: 'COMING SOON',
            clientImg: 'assets/img/testimonials/img1.jpg',
            clientName: '',
            clientDesignation: ''
        },
        {
            paragraphText: 'COMING SOON',
            clientImg: 'assets/img/testimonials/img2.jpg',
            clientName: '',
            clientDesignation: ''
        },
        {
            paragraphText: 'COMING SOON',
            clientImg: 'assets/img/testimonials/img3.jpg',
            clientName: '',
            clientDesignation: ''
        }
    ]
    testimonialsBtn: Btn[] = [
        {
            link: "/",
            icon: 'flaticon-view',
            text: 'Check Out All Reviews'
        }
    ]

}
class sectionTitleContent {
    subTitle : string;
    title : string;
    paragraphText : string;
}
class TestimonialsItemContent {
    paragraphText : string;
    clientImg : string;
    clientName : string;
    clientDesignation : string;
}
class Btn {
    link : string;
    icon : string;
    text : string;
}
